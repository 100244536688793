import React from 'react';

import PortfolioItemLayout from '../../components/portfolio/PortfolioItemLayout';

import f1 from '../../assets/promyk/define/f1.png';
import f2 from '../../assets/promyk/define/f2.png';
import f3 from '../../assets/promyk/define/f3.png';
import f4 from '../../assets/promyk/define/f4.png';
import f5 from '../../assets/promyk/define/f5.png';
import f6 from '../../assets/promyk/define/f6.png';
import f7 from '../../assets/promyk/define/f7.png';
import f8 from '../../assets/promyk/define/f8.png';
import f9 from '../../assets/promyk/define/f9.png';

import l1 from '../../assets/promyk/deliver/l1.png';
import l2 from '../../assets/promyk/deliver/l2.png';
import l3 from '../../assets/promyk/deliver/l3.png';
import l4 from '../../assets/promyk/deliver/l4.png';
import l5 from '../../assets/promyk/deliver/l5.png';
import l6 from '../../assets/promyk/deliver/l6.png';
import l7 from '../../assets/promyk/deliver/l7.png';
import l8 from '../../assets/promyk/deliver/l8.png';
import l9 from '../../assets/promyk/deliver/l9.png';
import l10 from '../../assets/promyk/deliver/l10.png';

import v1 from '../../assets/promyk/develop/v1.png';
import v2 from '../../assets/promyk/develop/v2.png';
import v3 from '../../assets/promyk/develop/v3.png';

import i1 from '../../assets/promyk/develop_ui/i1.png';

import x1 from '../../assets/promyk/develop_ux/x1.png';
import x2 from '../../assets/promyk/develop_ux/x2.png';
import x3 from '../../assets/promyk/develop_ux/x3.png';
import x4 from '../../assets/promyk/develop_ux/x4.png';
import x5 from '../../assets/promyk/develop_ux/x5.png';

import d1 from '../../assets/promyk/discover/d1.png';
import d2 from '../../assets/promyk/discover/d2.png';
import d3 from '../../assets/promyk/discover/d3.png';
import d4 from '../../assets/promyk/discover/d4.png';
import d5 from '../../assets/promyk/discover/d5.png';
import d6 from '../../assets/promyk/discover/d6.png';

import cover from '../../assets/promyk/cover-promyk.png';

const Promyk = () => (
    <PortfolioItemLayout
        mainImgSrc={cover}
        title="Twój Promyk"
        subtitle="Application used for virtual adoption of animals"
        imgPosition="30%"
        // listing
        sector="Pets"
        myRole="UX and UI designer and researcher"
        technology="Figma,Figma Mirror, FigJam, Canva, Mural"
        projectTime="03.2022-05.2022"
        // First section
        firstSectionTitle="Pets adoption made easier"
        firstSectionDescription={`Work on the application was based on phases:

1. Discover
2. Define
3. develop
4. develop UX
5. Develop UI
6. Deliver
`}
        // rest
        contentSections={[
            {
                title: 'Discovery',
                description: `I defined the problem for which I wanted to prepare an appropriate solution. Taking into account the competitive analysis and the empathy map prepared earlier, I decided to create a mobile application that would enable virtual adoption of the shelter's charges.`,
                images: [
                    { original: d1 },
                    { original: d2 },
                    { original: d3 },
                    { original: d4 },
                    { original: d5 },
                    { original: d6 },
                ]
            },
            {
                title: 'Research',
                description: `I conducted a series of interviews with users, thanks to which it was possible, first of all, to confirm the hypothesis "Virtual animal adoption is an excellent solution for people who would like to actually adopt a homeless animal, but for various reasons do not have the conditions to do so."
Five adults participated in the survey.
Through the in-depth interviews, I gained very useful conclusions.`,
                images: [
                ]
            },
            {
                title: 'Define',
                description: `After conducting research, formulating conclusions and summarizing the research process, I created 3 personae, User Journey Maps and User Stories. This made it possible to plan the functionality in the application and most importantly, make design assumptions.`,
                images: [
                    { original: f1 },
                    { original: f2 },
                    { original: f3 },
                    { original: f4 },
                    { original: f5 },
                    { original: f6 },
                    { original: f7 },
                    { original: f8 },
                    { original: f9 },
                ]
            },
            {
                title: 'Develop',
                description: `The next step was to develop the information architecture and User Flow. The most important was
- the appropriate adjustment of action marking in user paths
- prioritizing information, functionalities in the application that are in line with the expectations of potential users, giving them a hierarchy of values. `,
                images: [
                    { original: v1 },
                    { original: v2 },
                    { original: v3 },
                ]
            },
            {
                title: 'Develop UX',
                description: `I prepared Lo-fi mockups based on User flow - the process of registering, selecting an animal for adoption, learning about the animal's profile, choosing the type of help you want to offer, selecting and making a payment.`,
                images: [
                    { original: x1 },
                    { original: x2 },
                    { original: x3 },
                    { original: x4 },
                    { original: x5 },
                ]
            },
            {
                title: 'Develop UI',
                description: `Based on the previously prepared moddboard, Key Visual, benchmarking, I prepared High-level mockups of the path included in User Flow`,
                images: [
                    { original: i1 },
                ]
            },
            {
                title: 'Deliver',
                description: `A prototype for virtual adoption of an animal.`,
                images: [
                    { original: l1 },
                    { original: l2 },
                    { original: l3 },
                    { original: l4 },
                    { original: l5 },
                    { original: l6 },
                    { original: l7 },
                    { original: l8 },
                    { original: l9 },
                    { original: l10 },
                ]
            },
        ]}
        nextLink="/portfolio/covid"
    />
)

export default Promyk;